<template>
    <Skeleton v-if="isLoading" class="py-6 mb-6" />
    <div v-else class="w-100 h-100">
      <div class="w-100 px-sm-3">
        <div class="columns">
          <div class="column is-3 border-right mt-sm-2 pb-sm-0">
          <span class="averta-bold is-size-5">Pilih Area</span>
          <div class="area-wrapper mt-4">

            <!-- sliceArea1 -->
            <div v-if="sliceArea1.length > 0" class="mb-3 slice-area-wrapper">
              <b-button v-for="(item, index) in sliceArea1" :key="item.area_id" class="mr-3 mb-5 mb-sm-0 btn-tabs"
                :class="{ 'is-active': area === item.area_id }" @click="changeIsActive(item, index)">
                <div class="white-space-normal is-flex is-flex-direction-column is-position-relative">
                  <p class="averta-bold white-space-md-nowrap">{{ item.area_name }}</p>

                  <div v-if="handleIsFillForm(index) && showError" class="alert-icon-wrapper">
                    <AlertIcon />
                  </div>
                </div>
              </b-button>
            </div>
            <!-- sliceArea1 -->

            <!-- sliceArea2 -->
            <div v-if="sliceArea2.length > 0" class="mb-3 slice-area-wrapper">
              <b-button v-for="(item, index) in sliceArea2" :key="item.area_id" class="mr-3 mb-5 mb-sm-0 btn-tabs"
                :class="{ 'is-active': area === item.area_id }" @click="changeIsActive(item, index)">
                <div class="white-space-normal is-flex is-flex-direction-column is-position-relative">
                  <p class="averta-bold white-space-md-nowrap">{{ item.area_name }}</p>

                  <div v-if="handleIsFillForm(index) && showError" class="alert-icon-wrapper">
                    <AlertIcon />
                  </div>
                </div>
              </b-button>
            </div>
            <!-- sliceArea2 -->

            <!-- sliceArea3 -->
            <div v-if="sliceArea3.length > 0" class="mb-3 slice-area-wrapper">
              <b-button v-for="(item, index) in sliceArea3" :key="item.area_id" class="mr-3 mb-5 mb-sm-0 btn-tabs"
                :class="{ 'is-active': area === item.area_id }" @click="changeIsActive(item, index)">
                <div class="white-space-normal is-flex is-flex-direction-column is-position-relative">
                  <p class="averta-bold white-space-md-nowrap">{{ item.area_name }}</p>

                  <div v-if="handleIsFillForm(index) && showError" class="alert-icon-wrapper">
                    <AlertIcon />
                  </div>
                </div>
              </b-button>
            </div>
            <!-- sliceArea3 -->
          </div>
          </div>
          <div class="column is-9 pt-sm-0">
            <TabComponentPhoto
              :dataAreas="areas"
              :dataComponents="sliceComponents"
              :indexArea.sync="indexArea"
              :selectedAreaId.sync="area"
              :selectedComponentId.sync="selectedComponentId"
              :showError="showError"
              @handleValidateForm="handleValidateForm($event)"
              @handleIsChange="handleIsChange($event)"
              @handleChangeStep="handleChangePhoto($event)"
              @refreshData="refreshData($event)"/>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { AlertIcon } from '@/components/icons'
import Skeleton from '@/components/skeleton/Skeleton'
// import TabComponentPhoto from './TabComponentPhoto'

export default {
  name: 'FotoSurvey',
  props: ['showError', 'idSurveyUpdateItemPekerjaan'],
  components: {
    Skeleton,
    // eslint-disable-next-line vue/no-unused-components
    AlertIcon,
    TabComponentPhoto: () => import('./TabComponentPhoto.vue')
  },
  computed: {
    ...mapGetters({
      url: 'setup/getUrl',
      user: 'auth/user'
    })
  },
  data () {
    return {
      isLoading: true,
      area: null,
      areas: [],
      indexArea: null,
      validateForm: [],
      tabAreaIdOffering: null,

      selectedComponentId: null,
      sliceArea1: [],
      sliceArea2: [],
      sliceArea3: [],
      sliceComponents: {
        sliceComponent1: [],
        sliceComponent2: [],
        sliceComponent3: []
      }
    }
  },
  methods: {
    changeIsActive (props, index) {
      this.indexArea = index
      this.area = props.area_id
      this.selectedComponentId = props.components[0].component_id
      this.sliceAreaOrComponent('component', props.components)
    },
    handleChangePhoto (payload) {
      this.$store.commit('project/setDataFotoSurvey', this.areas)
      this.refreshData()
    },
    handleValidateForm (payload) {
      this.validateForm = payload
      if (this.validateForm.length < 1) {
        this.$emit('isCompleteValidate', true)
      } else {
        this.$emit('isCompleteValidate', false)
      }
    },
    handleIsChange (payload) {
      this.$emit('isChange', true)
    },
    handleIsFillForm (index) {
      if (this.validateForm.find(e => e.area === index)) {
        return true
      } else {
        return false
      }
    },
    sliceAreaOrComponent (type, array) {
      const result = []
      if (array.length > 13 && array.length < 19) {
        // Bagi menjadi dua bagian
        const middleIndex = Math.ceil(array.length / 2)
        result.push(array.slice(0, middleIndex))
        result.push(array.slice(middleIndex))
        result.sort((a, b) => b.length - a.length)
        if (type === 'area') {
          this.sliceArea1 = result[0]
          this.sliceArea2 = result[1]
          this.sliceArea3 = []
        } else if (type === 'component') {
          this.sliceComponents = {
            sliceComponent1: result[0],
            sliceComponent2: result[1],
            sliceComponent3: []
          }
        }
      } else if (array.length >= 19) {
        // Bagi menjadi tiga bagian
        const firstSplitIndex = Math.ceil(array.length / 3)
        const secondSplitIndex = 2 * firstSplitIndex
        result.push(array.slice(0, firstSplitIndex))
        result.push(array.slice(firstSplitIndex, secondSplitIndex))
        result.push(array.slice(secondSplitIndex))
        result.sort((a, b) => b.length - a.length)
        if (type === 'area') {
          this.sliceArea1 = result[0]
          this.sliceArea2 = result[1]
          this.sliceArea3 = result[2]
        } else if (type === 'component') {
          this.sliceComponents = {
            sliceComponent1: result[0],
            sliceComponent2: result[1],
            sliceComponent3: result[2]
          }
        }
      } else {
        // Jika kurang dari atau sama dengan 13, tidak perlu membagi
        result.push(array)
        result.sort((a, b) => b.length - a.length)
        if (type === 'area') {
          this.sliceArea1 = result[0]
          this.sliceArea2 = []
          this.sliceArea3 = []
        } else if (type === 'component') {
          this.sliceComponents = {
            sliceComponent1: result[0],
            sliceComponent2: [],
            sliceComponent3: []
          }
        }
      }
    },
    getDataFotoSurvey (props = {}) {
      const { isRefresh } = props
      if (!isRefresh) {
        this.isLoading = true
      }

      const payload = {
        url: this.url,
        survey_id: this.$route.params.surveyId ? this.$route.params.surveyId : this.idSurveyUpdateItemPekerjaan
      }
      this.$store
        .dispatch('project/getDataFotoSurvey', payload)
        .then(response => {
          const res = response.data.data

          if (!isRefresh) {
            this.indexArea = 0
          }
          // this.indexArea = 0
          // this.area = res[0].area_id

          if (this.user.roles[0].name === 'Konsultan Survey') {
            res.map((itemArea, indexArea) => {
              itemArea.components.map((itemComp, indexComp) => {
                itemComp.detail_components.map((itemDetail, indexDetail) => {
                  itemDetail.item.map((itemItem, indexItem) => {
                    const arrPhoto = itemItem[`photo_area_${itemArea.area_id}_component_${itemComp.component_id}_detail_${itemDetail.id}_item_${indexItem + 1}`]
                    if (arrPhoto && arrPhoto.length === 0) {
                      res[indexArea].components[indexComp].detail_components[indexDetail].item[indexItem][`photo_area_${itemArea.area_id}_component_${itemComp.component_id}_detail_${itemDetail.id}_item_${indexItem + 1}`] = [{}, {}]
                    } else if (arrPhoto && arrPhoto.length === 1) {
                      if (Object.keys(arrPhoto[0]).length < 1) {
                        res[indexArea].components[indexComp].detail_components[indexDetail].item[indexItem][`photo_area_${itemArea.area_id}_component_${itemComp.component_id}_detail_${itemDetail.id}_item_${indexItem + 1}`] = [{}, {}]
                      }
                    }
                  })
                })
              })
            })
          }

          this.areas = res
          this.isLoading = false

          if (!isRefresh) {
            this.selectedComponentId = this.areas[0].components[0].component_id
          }

          this.sliceAreaOrComponent('area', this.areas)
          this.sliceAreaOrComponent('component', this.areas[0].components)

          this.$store.commit('project/setDataFotoSurvey', res)
          this.$store.commit('project/setDataUpdateItemPekerjaanBS', {
            initFotoSurvey: res
          })

          if (this.area === null && !isRefresh) {
            this.area = res[0].area_id
          }

          if (isRefresh) {
            const temp = this.areas[this.indexArea]
            this.area = temp.area_id
            this.selectedComponentId = temp.components[0].component_id
            this.sliceAreaOrComponent('component', temp.components)
          }
        })
        .catch(error => {
          this.isLoading = false
          console.log(error)
          this.$buefy.toast.open({
            message: 'Gagal memuat data, silahkan coba lagi',
            type: 'is-danger'
          })
        })
    },
    refreshData (isRefresh) {
      this.getDataFotoSurvey({ isRefresh: true })
    }
  },
  mounted () {
    this.$emit('isCompleteValidate', true)
  },
  created () {
    this.getDataFotoSurvey()
  }
}
</script>

<style scoped>
.w-100 {
  width: 100%;
}

.h-100 {
  min-height: 85vh;
}

.white-space-normal {
  white-space: normal;
}

.btn-tabs {
  height: auto;
  border-radius: 100px;
  color: #9a9a9a;
  border: 1px solid #e1e1e1;
}

.btn-tabs.is-active {
  background: rgba(49, 133, 252, 0.1);
  color: #3185fc;
  border: 1px solid #3185fc;
}

.border-right {
  border-right: 1px solid #e1e1e1;
}

.durasi-wrapper {
  background: #fafafa;
  border-radius: 12px;
  width: 100%;
}

.disabled-cs {
  color: #868686;
  position: absolute;
  right: 15px;
  z-index: 2;
}

.input-cs {
  border: 1px solid #E1E1E1;
  border-radius: 10px;
  padding-right: 3.5em;
}

.input-durasi-wrapper {
  position: relative;
  width: 20em;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.area-wrapper {
  display: flex;
  flex-direction: column;
}

.alert-icon-wrapper {
  position: absolute;
  right: 10px;
  top: 10px;
}

@media only screen and (min-width: 768px) {
  .area-wrapper {
    max-height: 65vh;
    overflow: auto;
  }
}

@media only screen and (max-width: 768px) {
  .area-wrapper {
    white-space: nowrap;
    overflow-x: scroll !important;
  }

  .area-wrapper {
    -ms-overflow-style: none;
    /* Internet Explorer 10+ */
    scrollbar-width: none;
    /* Firefox */
  }

  .area-wrapper::-webkit-scrollbar {
    display: none;
    /* Safari and Chrome */
  }

  .white-space-md-nowrap {
    white-space: nowrap !important;
  }

  .border-right {
    border: none;
  }
}
@media only screen and (max-width: 600px) {
  .alert-icon-wrapper {
    position: relative !important;
    top: 0;
    right: 0;
    margin-left: 0.5rem;
  }

  .input-durasi-wrapper {
    width: 100%;
  }

  .px-sm-3 {
    padding: 0 20px;
  }

  .durasi-wrapper {
    padding-top: 18px !important;
    padding-bottom: 24px !important;
    border-radius: 0 !important;
  }

  .w-sm-100 {
    width: 100%
  }
}
</style>
